import { AutomatitCarousel } from './automatit_carousel.m.js';

import {
	getMapsHelper
} from './util/mapsHelper';


export const facilityControls = (function(){

	let my = {};
    
	my.init = function () {

		const mapToggle = document.getElementById('map_toggle');
		const galleryToggle = document.getElementById('gallery_toggle');
		
		mapToggle.addEventListener('click', e => {
			let mapBtn = e.currentTarget;
			if(mapBtn.classList.contains('active') && document.getElementById('facility_map').classList.contains('active')) {
				mapBtn.classList.toggle('active');
				document.getElementById('facility_map').classList.toggle('active');
			} else {
				mapBtn.classList.add('active');
				document.getElementById('facility_map').classList.add('active');
			}
			galleryToggle.classList.remove('active');
		});

		galleryToggle.addEventListener('click', e => {
			let galleryBtn = e.currentTarget;
			galleryBtn.classList.add('active');
			mapToggle.classList.remove('active');
			document.getElementById('facility_map').classList.remove('active');
		});
	};

	return my;
})();

export const facilityCarousel = (function(){

	let my = {};

	my.init = function () {
		if (document.getElementById('facility_carousel')) {
			let interval = 5000;
			if(window.facility_slide_images.length === 1){
				interval = false;
			}
			const options = {
				element: document.getElementById('facility_carousel'),
				images: window.facility_slide_images,
				imagesAsBackgrounds: true,
				intervalTiming: interval,
				showDots: true,
				useChevrons: false
			};
			AutomatitCarousel(options);
		}
	};

	return my;
})();

/* eslint-disable no-undef */
export const facilityMap = (function(){
	let my = {},
		map = document.getElementById('facility_map');

	my.init = function(){
		if(document.getElementById('facility_map')){
			const mapHelper = getMapsHelper();
			mapHelper.ready()
				.then(() => {
					const theMap = mapHelper.createMap({
						element: map,
						locationElementSelector: '#facility_map',
						useRichmarker: true,
						markerReducer: el => {
							return {
								lat: el.getAttribute('lat'),
								lng: el.getAttribute('lng'),
								content: `
                                <a class="map_pin" href="${el.getAttribute('link')}" target="_blank">
                                    <img src="/static/images/icons/map_pin.svg" alt="Visit facility" />
                                </a>
                                `
							};
						}
					});

					google.maps.event.addListenerOnce(theMap, 'idle', () => {
						theMap.setZoom(15);
					});
				});
		}
	};

	return my;
})();

export const facilityFilter = (function(){
	let my = {};

	function _facilityFilter() {

		const filters = document.querySelectorAll('#filters .filter');
		const cards = document.querySelectorAll('.unit_row');
		const noUnits = document.querySelector('#no_units');
		document.querySelector('#filters').addEventListener('click', e => {
			const clickedFilter = e.target.closest('.filter');
			let unitShown = 0;
			if(clickedFilter) {
				filters.forEach(i => {
					i.removeAttribute('active');
				});
				if(clickedFilter.classList.contains('filter')) {
					clickedFilter.toggleAttribute('active');
				}
				cards.forEach(i => {
					i.setAttribute('hide', true);
					if((i.getAttribute('data-props') === clickedFilter.getAttribute('filter') || clickedFilter.getAttribute('filter') === 'all')) {
						unitShown++;
						i.removeAttribute('hide');
					}
				});
				if(unitShown === 0){
					noUnits.style.display = 'block';
				}else {
					noUnits.style.display = 'none';
				}
			}
		});
	}

	my.init = function(){
		_facilityFilter();
	};

	return my;
})();

export const facilityUnitsView = (function(){

	let my = {};
    
	my.init = function () {
		
		const toggleContainer = document.getElementById('facility_rates_controls');
		const toggleButtons = document.querySelectorAll('#facility_rates_controls button');
		const unitsContainer = document.getElementById('facility_units');
		const units = document.querySelectorAll('.unit_row');

		toggleContainer.addEventListener('click', e => {
			const clickedView = e.target.closest('button');
			toggleButtons.forEach(toggleButton => {
				toggleButton.removeAttribute('active');
			});

			clickedView.toggleAttribute('active');

			if(clickedView.getAttribute('data-view') === 'card') {
				unitsContainer.classList.add('card_view');
				units.forEach(unit => {
					unit.classList.add('card');
				});
			} else {
				unitsContainer.classList.remove('card_view');
				units.forEach(unit => {
					unit.classList.remove('card');
				});
			}
		});
	};

	return my;
})();

export const facilityViewUnits = (function(){

	let my = {};
    
	my.init = function () {

		const units = document.querySelectorAll('.unit_row');

		const viewMoreToggle = document.getElementById('view_more');

		const viewLessToggle = document.getElementById('view_less');

		const filters = document.querySelectorAll('#filters .filter');
		
		const toggleUnits = (units) => {
			for (let i = 0; i < units.length; i++) {
				if(i > 5){
					units[i].classList.toggle('hide');
				}
			}
		};
		
		if(units.length <= 5){
			viewMoreToggle.style.display = 'none';
			viewLessToggle.style.display = 'none';
		} else {
			
			toggleUnits(units);
	
			viewMoreToggle.addEventListener('click', function(){
				toggleUnits(units);
				viewMoreToggle.classList.toggle('hide');
				viewLessToggle.classList.toggle('hide');
			});

			viewLessToggle.addEventListener('click', function(){
				toggleUnits(units);
				viewMoreToggle.classList.toggle('hide');
				viewLessToggle.classList.toggle('hide');
				document.getElementById('facility_rates').scrollIntoView({ behavior: "smooth", block: "start"});
			});

			filters.forEach(filter => {
				filter.addEventListener('click', function(){
					viewMoreToggle.style.display = 'none';
					viewLessToggle.style.display = 'none';
					units.forEach(unit => {
						unit.classList.remove('hide');
					});
				});
			});
		}
	};

	return my;
})();

export const facilityModal = (function(){

	let my = {};
    
	my.init = function () {

		const modal = document.getElementById('facility_smart_unit_modal_container');

		const closeButton = document.getElementById('modal_close');

		const toggles = document.querySelectorAll('.smart_unit');

		const modalToggle = () => {
			modal.classList.toggle('active');
		};

		toggles.forEach(toggle => {
	
			toggle.addEventListener('click', function(){
				modalToggle();
			});
		});

		closeButton.addEventListener('click', function(){
			modalToggle();
		});
		
	};

	return my;
})();

export const facilityFeatures = (function(){

	let my = {};
    
	my.init = function () {

		const viewAllFeatures = document.getElementById('view_all_features');
		const viewLessFeatures = document.getElementById('view_less_features');
		const facilityFeatures = document.querySelectorAll('#features .feature');

		const toggleFeatures= (facilityFeatures) => {
			for (let i = 0; i < facilityFeatures.length; i++) {
				if(i > 5){
					facilityFeatures[i].classList.toggle('hide');
				}
			}
		};

		toggleFeatures(facilityFeatures);

		viewAllFeatures.addEventListener('click', () => {
			viewAllFeatures.classList.toggle('hide');
			viewLessFeatures.classList.toggle('hide');
			toggleFeatures(facilityFeatures);
		});

		viewLessFeatures.addEventListener('click', () => {
			viewAllFeatures.classList.toggle('hide');
			viewLessFeatures.classList.toggle('hide');
			toggleFeatures(facilityFeatures);
		});
	};

	return my;
})();

export const facilityInfoToggle = (function(){

	let my = {};
    
	my.init = function () {
		
		const facilityInfoToggle = document.getElementById('facility_info_toggle');
		const facilityInfoContainer = document.getElementById('facility_info_items');

		facilityInfoToggle.addEventListener('click', e => {
			const clickedToggle = e.target;
			clickedToggle.classList.toggle('hide');
			facilityInfoContainer.classList.toggle('hide');
		});
	};

	return my;
})();
