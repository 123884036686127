import { AutomatitCarousel } from './automatit_carousel.m.js';

export const reviewsCaro = (function(){

	let my = {};

	my.init = function () {
		const caro = document.querySelector('#facility_reviews_caro');

		const options = {
			element: caro,
			intervalTiming: false,
			useChevrons: false,
			showDots: true,
		};
			
		window.reviewsCaro = AutomatitCarousel(options);
	};

	return my;
})();

export const reviewsMobileCaro = (function(){

	let my = {};

	my.init = function () {
		const mobileCaro = document.querySelector('#reviews_caro_mobile');

		const options = {
			element: mobileCaro,
			intervalTiming: false,
			useChevrons: false,
			showDots: true,
		};
			
		window.reviewsMobileCaro = AutomatitCarousel(options);
	};

	return my;
})();