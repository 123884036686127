import { initMobileNav } from './header';
import { initHomeMap } from './home';
import { contactSubmit } from './contact.js';
import { facilityControls, facilityCarousel, facilityMap, facilityFilter, facilityFeatures, facilityUnitsView, facilityViewUnits, facilityModal, facilityInfoToggle } from './facility.js';
import { reviewsCaro, reviewsMobileCaro } from './reviews.js';

Element.prototype.switchAttribute = function(attribute, val1, val2) {
	this.setAttribute(attribute, (this.getAttribute(attribute) === val1 ? val2 : val1));
};

initMobileNav();

if(document.querySelector('#home_map')) {
	initHomeMap();
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}

const initReviewsCaro = () => {
	const caroSettings = {
		gutter: 20,
		animationDuration: 1000,
		currentSlide: 0,
		currentPos: 0,
		breakpoints: {
			sm: 320,
			md: 450,
			lg: 750,
		},
		slideCount: {
			lg: 3,
			md: 2,
			sm: 1
		}
	};
	
	const _getCurrentBreakpoint = () => {
		let largest = 'sm';
		for(const [k, p] of Object.entries(caroSettings.breakpoints)) {
			if(window.innerWidth > p) {
				largest = k;
			}
		}
		return largest;
	};

	const cards = document.querySelectorAll('.review_card');
	const caroLeft = document.querySelector('#caro_left');
	const caroRight = document.querySelector('#caro_right');
	
	const updateSlidePositions = () => {
	
		let slideNum = -caroSettings.currentPos;

	
		cards.forEach(i => {
			const width = i.getBoundingClientRect().width;
			i.style.left = `${(width * slideNum) + ( caroSettings.gutter/2 ) + (caroSettings.gutter * slideNum)}px`;
			slideNum++;
		});
	
		document.querySelector('#reviews_caro').removeAttribute('unresolved');
	};
	
	const _slideCaro = startAt => {
		let start;
		let atEnd = false;
		const initialVal = caroSettings.currentSlide;
		if(startAt <= 0) {
			startAt = 0;
		} else {
			caroLeft.removeAttribute('hide');
		}

		if ((cards.length) - startAt <= caroSettings.slideCount[_getCurrentBreakpoint()]) {
			startAt = cards.length - caroSettings.slideCount[_getCurrentBreakpoint()];
			atEnd = true;
		} else {
			caroRight.removeAttribute('hide');
		}

		caroSettings.currentSlide = startAt;
		
		const animateSlides = (ts) => {
			start = start || ts;
			let progress = (ts - start)/caroSettings.animationDuration;
			progress = (1 - Math.pow(1 - progress, 3));
			progress = Math.min(1, progress);
			caroSettings.currentPos = initialVal + (startAt - initialVal) * progress;
			if(progress < 1) {
				requestAnimationFrame(animateSlides);
			} else {
				if(startAt === 0) {
					caroLeft.setAttribute('hide', true);
				}
				if(atEnd) {					
					caroRight.setAttribute('hide', true);
				}
			}
			updateSlidePositions();
		};
		requestAnimationFrame(animateSlides);
	};

	window.slideReviewsLeft = () => {
		_slideCaro(caroSettings.currentSlide - caroSettings.slideCount[_getCurrentBreakpoint()]);
	};

	window.slideReviewsRight = () => {
		_slideCaro(caroSettings.currentSlide + caroSettings.slideCount[_getCurrentBreakpoint()]);
	};

	window.addEventListener('resize', () => {
		_slideCaro(caroSettings.currentSlide);
		updateSlidePositions();
	});

	const theOverlay = document.querySelector('#review_overlay');

	const overlayStars = document.querySelector('#overlay_stars');
	const overlayName = document.querySelector('#overlay_name');
	const overlayText = document.querySelector('#overlay_text');

	document.querySelector('#reviews_caro_outer').addEventListener('click', e => {
		const clickedButton = e.target.closest('.review_read_more button');

		if(clickedButton) {
			const theCard = e.target.closest('.review_card');

			theOverlay.setAttribute('show', true);

			overlayName.innerHTML = theCard.getAttribute('name');
			overlayText.innerHTML = theCard.getAttribute('text');
			overlayStars.innerHTML = '';

			for(let i = 0; i < 5; i++) {
				const starImg = document.createElement('img');
				starImg.src = `/static/images/icons/${(i < theCard.getAttribute('rating'))?'star_full':'star_empty'}.svg`;
				overlayStars.append(starImg);

			}
		}

		if(e.target.closest('#overlay_close')) {
			theOverlay.removeAttribute('show');
		}
	});
	
	updateSlidePositions();
};

if (document.getElementById('home_reviews')) {
	initReviewsCaro();
}

if(document.getElementById('facility_carousel')){
	facilityCarousel.init();
}

if(document.getElementById('facility_map')){
	facilityMap.init();
}

if(document.getElementById('facility_controls')){
	facilityControls.init();
}

if(document.getElementById('filters')){
	facilityFilter.init();
}

if(document.getElementById('features')){
	facilityFeatures.init();
}

if(document.getElementById('view_more')){
	facilityViewUnits.init();
}

if(document.getElementById('facility_smart_unit_modal_container')){
	facilityModal.init();
}

if(document.getElementById('features')){
	facilityUnitsView.init();
}

if(document.getElementById('facility_info_items')){
	facilityInfoToggle.init();
}
if(document.querySelector('#facility_reviews_caro')){
	reviewsCaro.init();
}

if(document.querySelector('#reviews_caro_mobile')){
	reviewsMobileCaro.init();
}

if(document.querySelector('.overlay_options')){
	
	/* eslint-disable */
	$('.rates_button').click(function(e) {
		$('.rates_overlay').toggleClass("open");

		var button = e.currentTarget;
	
		if (!button) {
			return;
		}
	
		const scrollHeight = $(window).scrollTop();
	
		var groupIdentifier = button.getAttribute('data-group');
	
		var overlay = $(".overlay_options[data-group='" + groupIdentifier + "']");
	
		var overlayHeight = overlay.height();
	
		//var overlaySelHeight = $('.overlay_selection').height();
	
		if (overlay.length) {
			//>> activate the overlay
			overlay.toggleClass("open");
		} else {
			toRatesHandler(button, false);
		}
	});
	
	var toRatesHandler = (el, overlay) => {
	
		$('#back_button').click(function() {
	
			if (overlay == false) {
				$('.rates_overlay').removeClass("open");
				//$('.overlay_selection').removeClass("open");
			} else {
				//$('.overlay_selection').removeClass("open");
				$(overlay).toggleClass('open');
				//$('.rates_overlay').addClass("open");
				overlay = false;
			}
		});
		
		if (el.hasAttribute('data-inquiry-only')) {
			location.href = el.getAttribute('data-inquire');
		} else {
			var rentUrl = el.getAttribute('data-rent');
			var resUrl = el.getAttribute('data-res');
			$('.rent_overlay').attr("href", rentUrl);
			$('.reserve_overlay').attr("href", resUrl);
			$('.overlay_options.open').toggleClass("open");
			$('.rates_overlay').toggleClass("open");
			//window.open(rentUrl,'_self');
			//window.open(resUrl,'_self');
		}
	}
	
	$('.to_rates').click(function(e) {
		const openOverlay = $('.overlay_options.open');
		toRatesHandler(e.currentTarget, openOverlay);
		//$('.overlay_selection').addClass('open');
	});
	
	//>> close the rates if the overlay behind it is clicked
	$('.rates_overlay').click(function() {
		$('.rates_overlay').removeClass("open");
		$('.overlay_options').removeClass("open");
		//$('.overlay_selection').removeClass("open");
	});
	
	//>> close rates overlay if "X" is clicked
	$('.overlay_close').click(function() {
		$('.rates_overlay').removeClass("open");
		$('.overlay_options').removeClass("open");
		//$('.overlay_selection').removeClass("open");
	});
}